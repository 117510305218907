import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
// googlelogo
import googlelogo from '../../../assets/login/google-login-logo.png';
import facebooklogo from '../../../assets/login/f_logo_RGB-White_58.png';

import Image from '../../../components/Image';
import DividerWithText from './DividerWithText';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();


  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState();
  const [resendVerification, setResendVerification] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required').min(5, "Password must be at least 5 characters").trim(),
  });

  
  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    setResendVerification(false)
    setError();
    try {
      // alert("button event")
      // console.log("button event");
      await login(data.email, data.password);
      enqueueSnackbar('Login Successful');

      await new Promise((resolve) => setTimeout(resolve, 500));
      
      // if (isMountedRef.current) {
      //   onGetEmail(data.email);
      // }
    } catch(error) {
      // setError(error)
      if(error.response.status === 429){
        setError("Too many attempts, please wait for 15 mins before you can try again.")
      }
      else if(error.response.status === 500){
        setError("myTelecom is currently unavailable, please try again later!")
      }
      else if(error.response.data.error.message === "Email not verified"){
        setResendVerification(true)
      }
      else 
      setError(error.response.data.error.message)
    }
  };

  const onError = async (data) => {
    
      console.log("data", data);
  };

  console.log("resendVerication", resendVerification)
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
      { error && (<Alert severity="error">{error}</Alert>) }
      { resendVerification && (<Alert severity="error">Email not verified! <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resendVerification}>Click here</Link> to resend email verification link. </Alert>)}

        <RHFTextField name="email" label="Email Address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton> <br /><br />
      {/* <DividerWithText>Or</DividerWithText><br />
      <Button fullWidth size="large" variant="outlined">
      <img alt="login" width="18px" src={googlelogo} />
      &nbsp; &nbsp;Sign in with Google
      </Button><br /><br />

      <Button fullWidth size="large" variant="contained" color="primary">
      <img alt="login" width="21px" src={facebooklogo} />
      &nbsp; &nbsp;Log in With Facebook
      </Button> */}

      {/* <Button fullWidth size="large" variant="contained" color="primary" to={PATH_AUTH.loginconnect} component={RouterLink}>
        Log in With Connect
      </Button>  */}

    </FormProvider>
  );
}
