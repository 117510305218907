// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
export const CHAT = '/chat';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginconnect: path(ROOTS_AUTH, '/loginconnect'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  resendVerification: path(ROOTS_AUTH, '/resend-verification'),
  resendServiceVerification: path(ROOTS_AUTH, '/resend-service-verification'),
  verifyService: path(ROOTS_AUTH, '/verify-service'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  verifyEmail: path(ROOTS_AUTH, '/verification-required'),
  verify: path(ROOTS_AUTH, '/verify'),
  learnMore: path(ROOTS_AUTH, '/learn-more'),
  addServiceInitial: path(ROOTS_AUTH, '/add-service')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  accessDenied: '/accessdenied',
  // pricing: '/pricing',
  // payment: '/payment',
  // about: '/about-us',
  // contact: '/contact-us',
  // faqs: '/faqs',
  page404: '/404',
  service404: '/400',
  // page500: '/500',
  // components: '/components',
  // home: '/home'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,


    // Selfcare Config - Atishwar
    topup: path(ROOTS_DASHBOARD, '/topup'),
    serviceTopup: path(ROOTS_DASHBOARD, '/serviceTopup'),
    topupPay: path(ROOTS_DASHBOARD, '/topup/pay'),
    service: path(ROOTS_DASHBOARD, '/service'),
    payments: path(ROOTS_DASHBOARD, '/payments'),
    bills: path(ROOTS_DASHBOARD, '/bills'),
    billpay: path(ROOTS_DASHBOARD, '/bills/pay'),
    myCash: path(ROOTS_DASHBOARD, '/bills/pay/mycash'),
    anz: path(ROOTS_DASHBOARD, '/bills/pay/result'),
    account: path(ROOTS_DASHBOARD, '/account'),
    contact: path(ROOTS_DASHBOARD, '/contact'),
    addService: path(ROOTS_DASHBOARD, '/addservice'),
    addOtherService: path(ROOTS_DASHBOARD, '/addotherservice'),


  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),


  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
