import axios from 'axios';
// config
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { HOST_API } from '../config';
import { setSession } from "./jwt";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.request.use(
  (config) => 
    // Do something before request is sent
     config
  ,
  (error) => 
    // Do something with request error
     Promise.reject(error)
  );

axiosInstance.interceptors.response.use(
  (response) => response,
  // eslint-disable-next-line consistent-return
  async (error) => 

    // const originalRequest = error.config;
    // if (error.response.status === 401 && !originalRequest._retry && window.localStorage.getItem("accessToken")) {
    //   const clientInformation = {
    //     client_id: "1",
    //     client_secret: "76e6ce9a7e10d26ac315b76e9fa8783f83922291db48ffd7264b8a17c22a89c1"
    //   }
    //   originalRequest._retry = true;
    //   const response = await axiosInstance.post('/users/refresh',
    //     {
    //       refreshToken: window.localStorage.getItem('refreshToken'),
    //       ...clientInformation
    //     });
    //   if (response.status === 201) {
    //     // 1) put token to LocalStorage
    //     const { accessToken, refreshToken } = response.data;

    //     // 2) Change Authorization header
    //     setSession(accessToken, refreshToken);


    //     // 3) return originalRequest object with Axios.
    //     return axios(originalRequest);
    //   }
    // }

     Promise.reject(error)
  
);

const refreshAuthLogic = async (failedRequest) => {
  const refreshToken = window.localStorage.getItem('refreshToken');

  console.log("refreshToken", refreshToken)

  return axiosInstance.post("/users/refresh",{
    refreshToken,
    client_id: "1",
    client_secret:
      "76e6ce9a7e10d26ac315b76e9fa8783f83922291db48ffd7264b8a17c22a89c1",
    rememberMe: true,
  },{ skipAuthRefresh: true })
    .then(async (tokenRefreshResponse) => {
      if (failedRequest.response.config.headers.Authorization)
        failedRequest.response.config.headers.Authorization =
          `Bearer ${tokenRefreshResponse.data.accessToken}`;

      axiosInstance.defaults.headers.common.Authorization = `Bearer ${tokenRefreshResponse.data.accessToken}`;

      setSession(tokenRefreshResponse.data.accessToken, tokenRefreshResponse.data.refreshToken);

      // console.log(tokenRefreshResponse.data.accessToken);
      return Promise.resolve();
    })
    .catch((error) => {
      // console.log("erro", error.message);
      // if (error.message.includes("invalid_token")) {
      // authContext.signOut();
      // }
      console.error("errro over here", error);
    });
};

// Instantiate the interceptor
createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, {
  pauseInstanceWhileRefreshing: true,
  retryInstance: axiosInstance,
  statusCodes: [401],
});


export default axiosInstance;
