import { Box, Button, Link, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { useSnackbar } from 'notistack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
// @mui
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Iconify from '../../../components/Iconify';
// hooks
import useAuth from '../../../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2, 2, 0.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarServices.propTypes = {
  isCollapse: PropTypes.bool,
};

function ServiceDialog(props) {
  const { user } = useAuth();
  const accountsVerified = user?.accounts?.filter((account)=> account.verified === true)
  const { onClose, selectedValue, open } = props;
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = async (serviceAlias, value) => {
    await new Promise((resolve) => setTimeout(resolve,500));
    const delay = ms => new Promise(res => setTimeout(res, ms));
    enqueueSnackbar('Switching Service...', { variant: 'info' });
    onClose(value);
    await delay(1500);
    localStorage.setItem('serviceAlias', value)
    localStorage.setItem('activeAccount', serviceAlias)
    window.location.href = '/dashboard/app'
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle sx={{ m: 3, p: 1 }}>{user?.firstName}'s linked services</DialogTitle>
      <List sx={{ pt: 0 }}>
        {accountsVerified?.map((account) => (
          <ListItem button onClick={() => handleListItemClick(account.serviceNumber, account.serviceAlias)} key={account.id}>
            <ListItemAvatar>
              <Avatar>
                {/* <PersonIcon /> */}<Iconify icon={'clarity:network-globe-solid-badged'} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>{account.serviceNumber}{' '}{(account.serviceAlias) ? "- " : account.serviceAlias}<strong>{account.serviceAlias}</strong></ListItemText>
          </ListItem>
        ))}

        {user?.accountsLinked?.map((accountLinked) => (
          <ListItem button onClick={() => handleListItemClick(accountLinked?.account?.serviceNumber, accountLinked?.accountLinkAlias ?? accountLinked?.account?.serviceAlias)} key={accountLinked?.account?.id}>
            <ListItemAvatar>
              <Avatar>
                {/* <PersonIcon /> */}<Iconify icon={'clarity:network-globe-solid-badged'} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>{accountLinked?.account?.serviceNumber}{' '}{( accountLinked?.accountLinkAlias ?? accountLinked?.account?.serviceAlias) ? "- " : accountLinked?.accountLinkAlias ?? accountLinked?.account?.serviceAlias}<strong>{ accountLinked?.accountLinkAlias ?? accountLinked?.account?.serviceAlias}</strong></ListItemText>
          </ListItem>
        ))}

      </List>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleClose} component={RouterLink} to={PATH_DASHBOARD.addService}>Link Service</Button><Button color="warning" variant="contained" onClick={handleClose} component={RouterLink} to={PATH_DASHBOARD.user.account}>Edit/ Delete</Button>&nbsp;&nbsp;&nbsp;<Button color="error" variant="outlined" onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

ServiceDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};


export default function NavbarServices({ isCollapse }) {

  const { user } = useAuth();
  const [open, setOpen] = React.useState(false);
  const activeAccount = window.localStorage.getItem('activeAccount');
  const serviceAlias = window.localStorage.getItem('serviceAlias');
  const [selectedValue, setSelectedValue] = React.useState(activeAccount);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (

      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Link underline="none" color="inherit">
          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
                <div>
                  <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }} style={{width:200}} component="div">
                    {/* <strong>Alias:</strong> {user?.accounts[0]?.serviceAlias}<br /> */}
                    {/* <strong>PC No: </strong>{selectedValue}<br /> */}
                    <strong>Service Alias: </strong>{(serviceAlias === "null") ? "Alias not set" : serviceAlias} <br />
                    <strong>Service: </strong>{selectedValue}
                  </Typography>
                  <br />
                  <Button variant="outlined" onClick={handleClickOpen}>
                      Switch Service&nbsp;&nbsp; <Iconify icon={'akar-icons:link-out'} />
                  </Button>
                  <ServiceDialog
                    selectedValue={selectedValue}
                    open={open}
                    onClose={handleClose}
                  />
                </div>
          </Typography>
          </Link>
        </Box>
      </RootStyle>

  );
}
