import PropTypes from 'prop-types';
import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
// import navConfig from './NavConfig';
import NavbarDocs from './NavbarDocs';
import NavbarAccount from './NavbarAccount';
import NavbarServices from './NavbarServices';
import CollapseButton from './CollapseButton';
// import navConfig from './NavConfig';


// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';
import Iconify from '../../../components/Iconify';
import axios from '../../../utils/axios';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

  const ICONS = {
    blog: getIcon('ic_blog'),
    cart: getIcon('ic_cart'),
    chat: getIcon('ic_chat'),
    mail: getIcon('ic_mail'),
    user: getIcon('ic_user'),
    kanban: getIcon('ic_kanban'),
    banking: getIcon('ic_banking'),
    calendar: getIcon('ic_calendar'),
    ecommerce: getIcon('ic_ecommerce'),
    analytics: getIcon('ic_analytics'),
    dashboard: getIcon('ic_dashboard'),
    booking: getIcon('ic_booking'),
    usage: getIcon('ic_analytics'),
  };

  const navConfigStuff = ([
    // Selfcare
    // Selfcare Home
    // ----------------------------------------------------------------------
    {
      subheader: 'Dashboard',
      items: [
        { title: `Home`, path: PATH_DASHBOARD.general.app, icon: <Iconify icon={'clarity:home-solid'} width={20} height={20} /> },
      ],
    },

    // Selfcare Service
    // ----------------------------------------------------------------------
    {
      subheader: 'Service',
      items: [
        { title: 'Summary', path: PATH_DASHBOARD.service, icon: <Iconify icon={'carbon:wifi-controller'} width={20} height={20} /> },
        // { title: 'Usage', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
        // { title: 'Top Up', path: PATH_DASHBOARD.serviceTopup, icon: <Iconify icon={'fa6-solid:money-bill-transfer'} width={20} height={20} /> },
        { title: 'Top Up', path: PATH_DASHBOARD.topup, icon: <Iconify icon={'fa6-solid:money-bill-transfer'} width={20} height={20} /> },

      ],
    },

    // Selfcare Account
    // ----------------------------------------------------------------------
    {
      subheader: 'Account',
      items: [
        { title: 'Summary', path: PATH_DASHBOARD.account, icon: <Iconify icon={'ic:baseline-account-circle'} width={20} height={20} /> },
        { title: 'Transactions', path: PATH_DASHBOARD.payments, icon: <Iconify icon={'ant-design:dollar-circle-filled'} width={20} height={20} /> },
        { title: 'Bills', path: PATH_DASHBOARD.bills, icon: <Iconify icon={'ic:round-receipt'} width={20} height={20} /> },
        { title: 'Contact', path: PATH_DASHBOARD.contact, icon: <Iconify icon={'bxs:contact'} width={20} height={20} /> }
      ],
    },


    
    // // GENERAL
    // // ----------------------------------------------------------------------
    // {
    //   subheader: 'general',
    //   items: [
    //     { title: 'home', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
    //     { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
    //     { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
    //     { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
    //     { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    //   ],
    // },


    // // MANAGEMENT
    // // ----------------------------------------------------------------------
    // {
    //   subheader: 'management',
    //   items: [
    //     // MANAGEMENT : USER
    //     {
    //       title: 'user',
    //       path: PATH_DASHBOARD.user.root,
    //       icon: ICONS.user,
    //       children: [
    //         { title: 'profile', path: PATH_DASHBOARD.user.profile },
    //         { title: 'cards', path: PATH_DASHBOARD.user.cards },
    //         { title: 'list', path: PATH_DASHBOARD.user.list },
    //         { title: 'create', path: PATH_DASHBOARD.user.newUser },
    //         { title: 'edit', path: PATH_DASHBOARD.user.editById },
    //         { title: 'account', path: PATH_DASHBOARD.user.account },
    //       ],
    //     },

    //     // MANAGEMENT : E-COMMERCE
    //     {
    //       title: 'e-commerce',
    //       path: PATH_DASHBOARD.eCommerce.root,
    //       icon: ICONS.cart,
    //       children: [
    //         { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
    //         { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
    //         { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
    //         { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
    //         { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
    //         { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
    //         { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
    //       ],
    //     },

    //     // MANAGEMENT : BLOG
    //     {
    //       title: 'blog',
    //       path: PATH_DASHBOARD.blog.root,
    //       icon: ICONS.blog,
    //       children: [
    //         { title: 'posts', path: PATH_DASHBOARD.blog.posts },
    //         { title: 'post', path: PATH_DASHBOARD.blog.postById },
    //         { title: 'new post', path: PATH_DASHBOARD.blog.newPost },
    //       ],
    //     },
    //   ],
    // },

    // APP
    // ----------------------------------------------------------------------
    // {
    //   subheader: 'app',
    //   items: [
    //     {
    //       title: 'mail',
    //       path: PATH_DASHBOARD.mail.root,
    //       icon: ICONS.mail,
    //       info: (
    //         <Label variant="outlined" color="error">
    //           +32
    //         </Label>
    //       ),
    //     },
    //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
    //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
    //     {
    //       title: 'kanban',
    //       path: PATH_DASHBOARD.kanban,
    //       icon: ICONS.kanban,
    //     },
    //   ],
    // },
  ]);
  // const [data, setData]  = useState();
  // const [navConfig, setNavConfig] = useState([])

  // const id = window.localStorage.getItem('id');
  // const activeAccount = window.localStorage.getItem('activeAccount');
  // const getAllData = () => {
  //     axios
  //     .get(`/dashboard/${id}/${activeAccount}`)
  //       .then((res) => {
  //         // console.log(res.data.display);
  //         if (!Array.isArray(res.data.display)
  //         )
  //         { 
  //           const array = [];
  //           array[0] = res.data.display;
  //           // const navConfigStuff = navConfig;
  //           navConfigStuff.forEach((navItem, index) => {

  //           // ACCOUNT LEVEL STUFF

  //             if(navItem.subheader === "Account"){
  //                const stuff = navItem.items.filter((item, index) => {
  //                 return (!(item.title === "Bills" && res.data.display.viewBills === false))
  //               })
  //               navConfigStuff[index].items = stuff
  //             }
  //             if(navItem.subheader === "Account"){
  //                const stuff = navItem.items.filter((item, index) => {
  //                 return (!(item.title === "Payments" && res.data.display.viewPayments === false))
  //               })
  //               navConfigStuff[index].items = stuff
  //             }
  //             if(navItem.subheader === "Account"){
  //               const stuff = navItem.items.filter((item, index) => {
  //                return (!(item.title === "Contact" && res.data.display.editAccount === false))
  //              })
  //              navConfigStuff[index].items = stuff
  //            }
  //             if(navItem.subheader === "Account"){
  //                 const stuff = navItem.items.filter((item, index) => {
  //                 return (!(item.title === "Contact" && res.data.display.editAccount === false))
  //               })
  //               navConfigStuff[index].items = stuff
  //             }

  //           // SERVICE LEVEL STUFF
  //           if(navItem.subheader === "Service"){
  //               const stuff = navItem.items.filter((item, index) => {
  //               return (!(item.title === "Top Up" && res.data.display.purchaseAddon === false))
  //             })
  //             navConfigStuff[index].items = stuff
  //           }

  //           if(navItem.subheader === "Service"){
  //             const stuff = navItem.items.filter((item, index) => {
  //             return (!(item.title === "Usage" && res.data.display.usage !== "Data"))
  //           })
  //           navConfigStuff[index].items = stuff
  //         }
            
  //           })
  //           setNavConfig(navConfigStuff);
  //           setData(array)
  //         }
  //         else {setData(res.data.display);
  //         }
  //         // setData(res.data);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  // };

  

  const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();


  useEffect(() => {
      // getAllData();
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);



  

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" style={{ alignSelf: 'center' }}>
          <Logo />

          {/* {isDesktop && !isCollapse && (
            <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
          )} */}
        </Stack>

        <NavbarAccount isCollapse={isCollapse} />
        <NavbarServices isCollapse={isCollapse} />

      </Stack>

      {/* <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} /> */}
      <NavSectionVertical navConfig={navConfigStuff} isCollapse={isCollapse} />
      <Box sx={{ flexGrow: 1 }} />

      {/* {!isCollapse && <NavbarDocs />} */}
    </Scrollbar>
  )

  return (


    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
