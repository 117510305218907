import { useState, useEffect} from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { styled } from '@mui/material/styles';
import { Box, Stack, Link, Alert, Container, Typography, Card, Grid } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
// import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
import TextIconLabel from '../../components/TextIconLabel';
// import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';
// imagebanner
import Background from '../../assets/myTelecomBG2.png';
import TFLLogo from '../../assets/logo/logo.png';
import PlayStore from '../../assets/apk.png';
import AppStore from '../../assets/ios.png';

// ----------------------------------------------------------------------


const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

// const SectionStyle = styled(Card)(({ theme }) => ({
//   width: '100%',
//   maxWidth: 464,
//   display: 'flex',
//   maxHeight: '100vh',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   margin: theme.spacing(2, 2, 2, 2),
// }));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 600,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'left',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  // const { method } = useAuth();

  const smUp = useResponsive('up', 'sm');
  const { trackPageView, trackEvent } = useMatomo()
  useEffect(() => {
      trackPageView({
        documentTitle: 'Login', // optional
      })
    }, [])

  // const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <div style={{  
        backgroundImage: `url(${Background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        minHeight: '100vh',
      }}>
        {/* <ContentStyle > */}
          <div style={{ display:'flex', justifyContent:'left', padding:"15px", maxWidth: 600,  }}>
            <Card style={{ padding:"30px" }}>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
              <div align="center">
              <img alt="Telecom Fiji" src={TFLLogo} width="50%" /> <br /><br />
              </div>
                <Typography variant="h4" align="center" gutterBottom>
                  Sign in to myTelecom
                </Typography>
                <Typography sx={{ color: 'text.secondary' }} align="center">Enter your credentials below.</Typography>
              </Box>
            </Stack>

            {/* <Alert severity="info" sx={{ mb: 3 }}>
              We are <strong> new and fresh</strong>. Enjoy managing your account and service(s) with the <strong> revamped </strong> myTelecom portal.
            </Alert> */}
            <Alert severity="info" sx={{ mb: 3 }}>
              Now you can <strong> purchase topup online or pay bills online</strong> via myTelecom. Checkout via<strong> Visa Credit/ Debit, Mastercard, BSP Easy Card, MyCash or via M-PAiSA </strong>24/7.
            </Alert>


            <LoginForm />

            <Typography variant="body2" align="center" sx={{ mt: 1 }}>
                Don’t have an account?{' '}
                <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                  Create a myTelecom Account
                </Link>
            </Typography><br />
            <Typography variant="body2" align="center" sx={{ mt: 1 }}>
                <strong>Get more features using the myTelecom app. Download now via Google Play Store and Apple App Store.</strong>
            </Typography>
            <br />
            <Stack spacing={2.5} alignItems="center" direction="row" justifyContent="center">
              <div>
              <Link
                href="https://apps.apple.com/us/app/mytelecom/id1626209032"
                target="_blank"
                rel="noopener"
                color="common.black"
                sx={{ typography: 'body2' }}
              >
                <TextIconLabel
                  icon={
                    <Image
                      alt="sketch icon"
                      src={AppStore}
                      sx={{ width: 124, height: 41, mr: 1 }}
                    />
                  }
                />
              </Link>
              </div>

              <div>
              <Link
                href="https://play.google.com/store/apps/details?id=com.telecom.my"
                target="_blank"
                rel="noopener"
                color="common.black"
                sx={{ typography: 'body2' }}
              >
                <TextIconLabel
                  icon={
                    <Image
                      alt="sketch icon"
                      src={PlayStore}
                      sx={{ width: 124, height: 41, mr: 1 }}
                    />
                  }
                />
              </Link>
              </div>
            </Stack>
            </Card>
          </div>
          {/* </ContentStyle> */}
      </div>
    </Page>
  );
}
